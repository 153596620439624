<div
  class="table-form-field table-checkbox-field"
  *ngIf="ready"
  [ngClass]="{
    'table-is-required': field.templateOptions?._hasRequired,
    'table-disabled': formControl.disabled && mode === FormModes.EDIT
  }"
>
  <fr-table
    [config]="tableConfig"
    [(data)]="tableData"
    [paginatorConfig]="paginatorConfig"
    [Id]="formState.Id + '-' + field.id"
    [disabled]="formControl.disabled"
    iscFormDisabledControl
    [iscFormDisabledFormControl]="formControl"
    [clearOnDisable]="field.clearOnDisable"
    id="{{ formState.Id }}-{{ field.id }}"
  >
    <div *ngIf="field.templateOptions?._hasRequired" table-title-text-space class="table-title-text-space">
      <span class="table-required-asterisk">*</span>
    </div>
  </fr-table>
  <formly-validation-message
    *ngIf="mode === 'edit' && showError"
    [fieldForm]="formControl"
    [field]="field"
  ></formly-validation-message>
</div>
