import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import {
  TableConfig,
  PaginatorConfig,
  DEFAULT_ROW_CHECKED_STATUS_FIELD,
  EPresetOptions,
  NewButton,
} from '@intersystems/table';

import { Field, CheckBoxTableConfig, Option, FormModes, FormState } from '@intersystems/isc-form';

import { cloneDeep } from 'lodash-es';

import { Subscription } from 'rxjs';

@Component({
  selector: 'isc-form-checkbox-table',
  templateUrl: './checkbox-table.component.html',
  styleUrls: ['./checkbox-table.component.scss'],
})
export class CheckboxTableComponent extends FieldType implements OnInit, OnDestroy {
  field: Field;

  tableConfig: TableConfig = null;
  tableData: any[] = [];

  mode: FormModes = null;
  FormModes = FormModes;

  ready = false;

  pauseFieldFormControlValueChangesListener = false;

  paginatorConfig: PaginatorConfig = {
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50],
  };

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.mode = this.formState._mode;

    if ((this.field.data as CheckBoxTableConfig).hidePaginator || this.mode === FormModes.VIEW) {
      this.paginatorConfig = null;
    } else {
      this.paginatorConfig = (this.field.data as CheckBoxTableConfig).paginatorConfig
        ? (this.field.data as CheckBoxTableConfig).paginatorConfig
        : this.paginatorConfig;
    }

    this.setupRowCheckboxModel(this.field.templateOptions.options);
    if (this.field.templateOptions.asyncOptions) {
      let optionsReceived = false;
      this.subscriptions.push(
        this.field.templateOptions.asyncOptions(this.field, this.formState, this.model).subscribe({
          next: (options: Option[]) => {
            this.toggleReady(false);
            this.field.templateOptions.options = cloneDeep(options);
            this.setupRowCheckboxModel(this.field.templateOptions.options);
            if (optionsReceived && this.field.templateOptions.unsetModelWhenOptionsUpdated) {
              this.setFieldFormControlValueInternal([]);
            }
            this.setupTable();

            this.toggleReady(true);
            optionsReceived = true;
          },
        }),
      );
    } else {
      this.toggleReady(false);

      this.setupTable();

      this.toggleReady(true);
    }

    if (this.mode === FormModes.EDIT) {
      this.subscriptions.push(
        /**
         * this valueChanges subscription should ONLY respond to external setValue() calls to this field.
         *
         * This field, under the hood, uses fr-table, which has its own scoped variables for managing checked rows
         * When this field's value was updated by an external setValue() call - then we must ensure that the table
         * refresh all of its scoped variables
         *
         * This is not necessary when the field's value was updated internally - e.g. users interact with the table
         * (check/uncheck boxes) - the table propagate that change to this field, and both the table's models and the
         * field's models are kept in sync.
         *
         */
        this.field.formControl.valueChanges.subscribe(() => {
          if (!this.pauseFieldFormControlValueChangesListener) {
            // the table data has been changed from outside of its control
            this.syncOptionsModelWithFormModel();
            // trigger table data update
            this.tableData = cloneDeep(this.field.templateOptions.options);
          }
        }),
      );
    }
  }

  toggleReady(state: boolean) {
    this.ready = state;
    this.cdr.detectChanges();
  }

  /**
   * Use this method to update the field value without causing the field's own valueChanges() listener to be triggered,
   * which is meant to catch external changes (when setValue is called from the outside)
   *
   * However, it will still broadcast the change so that outside subscriptions to valueChanges() will be triggered
   */
  private setFieldFormControlValueInternal(value) {
    this.pauseFieldFormControlValueChangesListener = true;
    this.field.formControl.setValue(value);
    this.pauseFieldFormControlValueChangesListener = false;
  }

  setupTable() {
    this.tableConfig = this.getCheckboxTableConfig(
      this.mode,
      this.field,
      this.formState,
      this.model,
      this.onRowsChecked,
    );

    if (this.mode === FormModes.VIEW) {
      this.tableData = this.field.formControl.value
        ? this.field.formControl.value.map(row => {
            row[DEFAULT_ROW_CHECKED_STATUS_FIELD] = null;
            return row;
          })
        : [];
    } else {
      this.tableData = this.field.templateOptions.options ? this.field.templateOptions.options : [];
    }
  }

  getCheckboxTableConfig(
    mode: FormModes,
    field: Field,
    formState: FormState,
    model: any,
    onRowsCheckedCallback: (rowsChecked, isCheck, allrowsChecked) => void,
  ) {
    const checkBoxTableConfig = field.data as CheckBoxTableConfig;

    const newButtonConfig = checkBoxTableConfig.tableHeaderButton;
    let newButton: NewButton;

    // if tableHeaderButton AND (mode is edit or (mode is view and showInViewMode truthy) )
    if (newButtonConfig && (mode === FormModes.EDIT || (mode === FormModes.VIEW && newButtonConfig.showInViewMode))) {
      newButton = cloneDeep(newButtonConfig.buttonConfig as NewButton);
      newButton.onClick = () => {
        newButtonConfig.buttonConfig.onClick(field, model, formState);
      };
      newButton.id = formState.Id + '-' + field.id + '-header-button';
    }

    const tableConfig: TableConfig = {
      key: field.key as string,
      header: {
        title: field.templateOptions.label,
        newButton: newButton,
      },
      noDataMessage: checkBoxTableConfig.noDataMessage
        ? checkBoxTableConfig.noDataMessage
        : 'fr-isc-form.table.EMPTY_MESSAGE',
      useSearch: checkBoxTableConfig.useSearch,
      searchConfig: cloneDeep(checkBoxTableConfig.searchConfig),
      sort: cloneDeep(checkBoxTableConfig.sort),
      rowIdentifierProperty: checkBoxTableConfig.uniqueValueField,
      columns:
        mode === FormModes.EDIT
          ? [
              {
                key: 'optionCheckStatus',
                id: 'optionCheckStatus',
                title: '',
                cellDisplay: {
                  preset: EPresetOptions.CHECKBOX,
                  [EPresetOptions.CHECKBOX]: {
                    rowsChecked: field.templateOptions.options.filter(
                      option => option[DEFAULT_ROW_CHECKED_STATUS_FIELD],
                    ),
                    onRowsChecked: onRowsCheckedCallback,
                    id: formState.Id + '-' + field.id + '-checkbox',
                    rowDisabledStatusField: 'disabled',
                    // Hide "Select All" checkbox if no options can be selected
                    hideCheckAllBoxes: field.templateOptions.options.every(option => option.disabled),
                  },
                },
              },
            ]
          : [],
    };

    // iterate over the field.data.displayFields to get the column title and model
    checkBoxTableConfig.displayFields.forEach((displayField, index) => {
      tableConfig.columns.push({
        key: displayField.displayField,
        id: displayField.displayField,
        sortable: displayField.sortable,
        title: displayField.columnTitle,
        cellDisplay: {
          model: displayField.displayField,
          bolded: displayField.bolded,
        },
      });
    });

    const commentsColumnConfig = (field.data as CheckBoxTableConfig).commentsColumn;

    // if commentsColumn AND (mode is edit or (mode is view and editableInViewMode truthy) )
    // instantiate the column as editable cell
    if (
      commentsColumnConfig &&
      (mode === FormModes.EDIT || (mode === FormModes.VIEW && commentsColumnConfig.editableInViewMode))
    ) {
      const editableCellConfig = cloneDeep(commentsColumnConfig.editableCellConfig);

      // wrap the custom disabled logic
      const fdnAuthorCommentDisabledFlag = commentsColumnConfig.editableCellConfig.disabled;
      editableCellConfig.disabled = (row, col) => {
        if (fdnAuthorCommentDisabledFlag && typeof fdnAuthorCommentDisabledFlag === 'function') {
          return row.disabled || fdnAuthorCommentDisabledFlag(row, col);
        } else if (fdnAuthorCommentDisabledFlag && typeof fdnAuthorCommentDisabledFlag === 'boolean') {
          return row.disabled || fdnAuthorCommentDisabledFlag;
        } else {
          return row.disabled;
        }
      };

      // wrap the custom onEdit callback
      const fdnAuthorEditableCellOnEditCallback = commentsColumnConfig.editableCellConfig.onEdit;

      const rowUniqueIdentifier = (field.data as CheckBoxTableConfig).uniqueValueField;
      editableCellConfig.onEdit = (rowEdited, col) => {
        // because editable cells only mutate the options, we need to also mutate the form model (the saved options)
        const editedRowInFormModel = field.formControl.value.find(
          rowChecked => rowChecked[rowUniqueIdentifier] === rowEdited[rowUniqueIdentifier],
        );
        if (editedRowInFormModel) {
          editedRowInFormModel[editableCellConfig.model] = rowEdited[editableCellConfig.model];

          // need to also emit a setValue() to update the model & trigger any subscriptions
          field.formControl.setValue(field.formControl.value);
        }

        if (fdnAuthorEditableCellOnEditCallback) {
          fdnAuthorEditableCellOnEditCallback(rowEdited, col);
        }
      };

      tableConfig.columns.push({
        key: 'commentsColumn',
        id: 'commentsColumn',
        title: commentsColumnConfig.columnTitle,
        cellDisplay: {
          preset: EPresetOptions.EDITABLE,
          [EPresetOptions.EDITABLE]: editableCellConfig,
        },
      });
    }
    // else instantiate the column as simple model column
    else if (commentsColumnConfig && mode === FormModes.VIEW) {
      tableConfig.columns.push({
        key: 'commentsColumn',
        id: 'commentsColumn',
        title: commentsColumnConfig.columnTitle,
        cellDisplay: {
          model: commentsColumnConfig.editableCellConfig.model,
        },
      });
    }

    return tableConfig;
  }

  onRowsChecked = (_rowsChecked: any[], _isCheck: boolean, allRowsChecked: any[]) => {
    allRowsChecked.forEach(row => {
      if (row.disabled) {
        delete row[DEFAULT_ROW_CHECKED_STATUS_FIELD];
      }
    });

    // Disabled rows should not be checked when "Select All" is checked
    const allRowsCheckedForModel = cloneDeep(allRowsChecked)
      .filter(row => !row.disabled)
      .map(row => row[(this.field.data as CheckBoxTableConfig).uniqueValueField]);
    this.setFieldFormControlValueInternal(allRowsCheckedForModel);
    this.model[this.field.key] = allRowsCheckedForModel;
  };

  setupRowCheckboxModel(options: Option[]) {
    if (!options) {
      this.field.templateOptions.options = [];
    }

    if (!this.field.formControl.value) {
      this.setFieldFormControlValueInternal([]);
    }

    this.syncOptionsModelWithFormModel();
  }

  syncOptionsModelWithFormModel() {
    // identify all checked rows and their possible comments
    const selectedRowsMap = {};
    this.field.formControl.value?.forEach(value => {
      selectedRowsMap[value] = true;
    });

    // iterate over the field.formControl.value and set the checked status in each option row
    // also if the form model has comments, copy it over to the option as well
    this.field.templateOptions.options.forEach(option => {
      const optionChecked = selectedRowsMap[option[(this.field.data as CheckBoxTableConfig).uniqueValueField]];

      option[DEFAULT_ROW_CHECKED_STATUS_FIELD] = optionChecked;

      if (optionChecked && (this.field.data as CheckBoxTableConfig).commentsColumn) {
        option[(this.field.data as CheckBoxTableConfig).commentsColumn.editableCellConfig.model] =
          optionChecked.comment;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
